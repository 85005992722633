import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: { [key: string]: string } = {};

  constructor(private http: HttpClient) {}

  private firebaseConfig: { [key: string]: string } = {};

  loadFirebaseConfig(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.get('assets/config.txt', { responseType: 'text' }).subscribe(
        (data) => {
          this.parseFirebaseConfig(data);
          resolve();
        },
        (error) => {
          console.error('Error loading Firebase config:', error);
          reject(error);
        }
      );
    });
  }

  private parseFirebaseConfig(data: string): void {
    const lines = data.split('\n');
    lines.forEach((line) => {
      const parts = line.split('=');
      if (parts.length === 2) {
        const key = parts[0].trim();
        const value = parts[1].trim();
        this.firebaseConfig[key] = value;
      }
    });
  }

  getFirebaseConfig(): { [key: string]: string } {
    return this.firebaseConfig;
  }
}
