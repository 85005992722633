// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  app_name: "SMART Quizz",
  // api_url: "https://qzbo.consonaute.biz/foot/api/",
  api_url: "https://smart-quizz.zen-apps.com/api/",
  custom_api_url: "https://smart-quizz.zen-apps.com/back-end/api.php",
  base_link: "https://foot.archivesdafrique.com/quiz",
  key_token: "access_token",
  key_user_data: "user_data",
  key_lang_data: "lang_data",
  recaptcha_site_key: "6LdZtG0iAAAAACWb0qDPg-SQWPSHx5b0xdrfI5Zi",
  recaptcha_key: "6LdZtG0iAAAAAJNUXQ-pfgbuYaRwfsHu6Mg91mBh",
  // recaptcha_site_key: '6Le8RcQpAAAAACAuJf8DKY9s0116t9_zZ5zvZINx',
  // recaptcha_key: '6Le8RcQpAAAAAKUYQvmKxO9w9xRkL73vdk4lDWlh',
  // firebase: {
  //   apiKey: "AIzaSyAAf5RlyUulaTVyQNPr_HIg0sM7bK2x0BA",
  //   authDomain: "archive-afrique-connect.firebaseapp.com",
  //   projectId: "archive-afrique-connect",
  //   storageBucket: "archive-afrique-connect.appspot.com",
  //   messagingSenderId: "742021701273",
  //   appId: "1:742021701273:web:f38c661c953fc9c5f5e208",
  // },
  firebase: {
    apiKey: "AIzaSyCG2G34kJ35YhpzxOWE_29nnf2LDslY3Ig",
  authDomain: "smart-quizz-30da9.firebaseapp.com",
  projectId: "smart-quizz-30da9",
  storageBucket: "smart-quizz-30da9.appspot.com",
  messagingSenderId: "206782191918",
  appId: "1:206782191918:web:9ff9055074c25b91ae42bf"
  },

  // firebase: {
  //   apiKey: "AIzaSyA0z97vEnmB6ws96JjNr9_2Ui2BbFkYZ3E",
  //   authDomain: "sabi-foot-quiz.firebaseapp.com",
  //   projectId: "sabi-foot-quiz",
  //   storageBucket: "sabi-foot-quiz.appspot.com",
  //   messagingSenderId: "278178852237",
  //   appId: "1:278178852237:web:611138ca54b0de5c91e1c4",
  //   measurementId: "G-YJQGWMP18M"
  // },

  // firebase: {
  //   apiKey: "AIzaSyBLrtzC6A38W7QDL28RDJ-4q-iKgBGWjb0",
  // authDomain: "smart-quizz-3.firebaseapp.com",
  // projectId: "smart-quizz-3",
  // storageBucket: "smart-quizz-3.appspot.com",
  // messagingSenderId: "365869566723",
  // appId: "1:365869566723:web:6011cc3948fd441016f736"
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
